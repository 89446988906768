<template>
  <main>
    <div class="error">{{ errorTerms }}</div>
    <div class="page-content">
      <h3>Terms of Service</h3>
      <div v-if="errorTerms">{{ errorTerms }}</div>
      <div v-for="term in terms" :key="term.id">
        <p class="story-text" v-html="term.details"></p>
      </div>
    </div>
  </main>
  <Footer v-if="terms" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import getCollectionStatic from "@/composables/getCollectionStatic";

export default {
  name: "Terms",
  components: { Footer },
  setup() {
    const { error: errorTerms, documents: terms } = getCollectionStatic(
      "rhTerms",
      null,
      ["createdAt", "desc"],
      1
    );

    return { errorTerms, terms };
  }
};
</script>

<style>
</style>